html {
  width: 100%;
  height: 100%;
}

.crescent {
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  z-index: -1;
  flex-direction: column;
  fill: #ffcc00;
}

.crescent > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.crescent > svg path {
  fill: #ffcc00;
}

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  height: 100vh;
}

.homepage-content {
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-top: 1rem; 
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center; 
  margin-top: 1rem;
  margin-left: 5rem; 
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; 
  margin-top: 1rem; 
}

.IntakeButton {
  padding: 70px 80px; 
  background-color: #ffcc00;
  color: #333;
  font-weight: bold;
  font-size: larger;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem; 
  margin-bottom: 100px;
  animation-name: shake;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transition: transform 0.3s;
}

.RadianceBrochureButton {
  padding: 30px 30px;
  background-color: #ffcc00;
  color: #333;
  font-weight: bold;
  font-size: larger;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
 
}


/* Define the shake animation */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.Rad-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -25rem;
}
.content a {
  color: black;
  
}

.IntakeButton:hover {
  animation-name: shake;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.IntakeButton:not(:hover) {
  transform: none;
}

.menu-toggle img {
  width: 50px;
  height: 50px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  margin: auto;
  position: relative; 
}

.menu-toggle {
  cursor: pointer;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.menu-items {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: calc(100% + 10px); 
  left: 50%; 
  transform: translateX(-50%); 
  z-index: 2;
}

.menu.open .menu-items {
  display: block;
  color: rgb(255, 225, 0);
}

nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s;
  padding: 10px 20px; 
  display: block;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 5px; 
}

nav a:hover {
  color: #888;
}

nav a:hover {
  color: #ffcc00;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-page {
  display: flex;
}

.about-crescent {
  width: 30%;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  fill: #ffcc00;
}

.about-crescent > svg {
  width: 100%;
  height: 100%;
}

.about-content {
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 30%; 
  margin-top:-5rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 1rem auto 0; 
}

.about-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

.about-title {
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #ffcc00;
}
.about-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10rem; 
  margin-bottom: 4rem; 
}

.about-logo img {
  width: 500px;
  margin-top: -50px; 
}

.specialties-container {
  background-color: white;
  border-radius: 20px;
  padding: 2rem 1rem 2rem 2rem;
  margin-top: -11rem;
  margin-bottom: 2rem;
  overflow-y: auto;
  max-height: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px #ffcc00;
  width: 90%; 
}


.specialty-section img {
  width: 100px; 
  height: 100px; 
  margin-bottom: 1rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.specialty-section span {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #ffcc00;
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100%; 
}

.specialty-section ul {
  list-style-type: disc;
  margin-left: 0;
  padding-left: 3rem; 
  margin-top: 0.5rem;
  justify-content: space-between;
}

.specialty-section ul li {
  margin-bottom: .5rem;
  white-space: nowrap; 
}

.specialty-section ul li::before {
  content: '\2022'; 
  color: #ffcc00; 
  margin-right: 0.5rem;
}
.specialty-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specialty-section img {
  width: 100px;
}

.specialty-section span {
  margin-top: 0.5rem;
  font-weight: bold;
}

.specialty-section ul {
  text-align: center;
  padding: 0;
  margin-top: 0.5rem;
  list-style-type: none;
}

.specialty-section ul li {
  margin-top: 0.25rem;
}


.locations {
  margin-top: 1rem;
}

.locations h2 {
  margin-bottom: 1rem;
  color: #ffcc00;
  margin-left: 150px;
}

.locations-list-container {
  display: flex;
  grid-gap: 0.5rem;
  max-height: 500px; 
  overflow-y: 7uto; 
  margin-left: -1rem;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2rem;
 
}

.locations-list {
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px #ffcc00;
  margin-bottom: 1rem;
  column-count: 3;
  grid-gap: 5rem;
  width: 100%; 
  max-width: 1200px; 
  margin-left: auto;
  margin-right: auto;
 
}

.locations-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  
}
.locations-list::before {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}


.locations-list li:hover {
  background-color: #ffcc00;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.Info {
  font-style: italic;
  margin-top: 1rem;
  justify-content: center;
  margin-left: 300px;
}

.Info a {
  color: black;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.1rem;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 0.5rem;
}






@media screen and (max-width: 768px) {
  /* Styles for screens up to 768px width */
  
  .homepage-container {
    justify-content: flex-start; 
  }

  .about-container {
    margin-top: 2rem; 
  }

  .specialties-container {
    margin-top: 0; 
  }

  .specialties {
    flex-direction: column; 
    align-items: center;
  }

  .specialties li {
    width: 100%; 
    margin-bottom: 1rem; 
  }

  .about-logo img {
    width: 300px; 
    margin-left: 0; 
    margin-bottom: 1rem; 
  }

  .locations h2 {
    margin-left: 0; 
  }

  .locations-list-container {
    margin-left: 0; 
  }
  .menu {
    max-width: none; 
    margin: 0 auto; 
  }
  .menu-items {
    top: 50px; 
    top: calc(100% + 5px);
  }
  .Rad-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -5rem;
  }
 
}





/* Add media queries for responsive breakpoints */
@media (max-width: 600px) {
  /* Styles for small screens */
}



/* Style buttons to be easy to tap on touch screens */
button {
  padding: 10px 15px;
  font-size: 1rem;
}

/* Size tap targets appropriately */
a, button {
  min-height: 48px;
  min-width: 48px;  
}

/* Use relative units for media */
img, video {
  max-width: 100%;
  height: auto;
}

/* Simplify navigation for small screens */
.menu {
  flex-direction: column;
}

/* Check font sizes on mobile */ 
* {
  -webkit-text-size-adjust: 100%;
}


@media (width: 414px) {
  /* iPhone 14 Max styles */
}



/* IntakeForm.css
.intake-form-container {
  max-width: 950px; 
  margin: 0 auto;
  padding: 10px; 
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; 
}


.form-section {
  margin-bottom: 15px; 
}

.form-section h2 {
  font-size: 1.5rem; 
  margin-bottom: 8px; 
  color: #333;
}


.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 3px; 
}

.form-input,
.form-select {
  width: 100%;
  padding: 8px; 
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 14px; 
  transition: border-color 0.3s ease;
}


.checkbox-group label {
  display: flex;
  align-items: center;
  font-size: 14px; 
  cursor: pointer;
}

.checkbox-group input {
  margin-right: 3px; 
}


button[type="submit"] {
  display: block;
  margin: 10px auto 0;
  padding: 10px 20px; 
  font-size: 14px; 
}


.submission-status {
  margin-top: 15px; 
  padding: 8px;
  font-size: 14px; 
}


.intake-form-container {
  background-color: #f2f6fa;
  padding: 20px; /* Increased padding for better spacing */
/* }


.specialty-group,
.spine-group,
.treatment-since-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px; 
}
.extremities-left-group,
.extremities-right-group{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5px;
  width: calc(50% - 10px);
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px; 
}

.checkbox-label input {
  margin-right: 5px;
  vertical-align: middle;
}
.checkbox-group-container {
  width: 50%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.checkbox-group-header {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
}
.checkbox-group label {
  margin-right: 15px;
  display: block;
  
}



@media screen and (max-width: 768px) {
  .checkbox-label {
    margin-right: 10px;
  }
}



.case-info-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Add space between elements */
  /* align-items: center; 
  margin-bottom: 20px;
}

.case-info-column {
  flex: 1;
  margin-right: 20px;
}

.case-info-group .form-field {
  flex: 1; 
  margin-right: 20px; 
}
.case-info-group .checkbox-group-header {
  margin: 0; 
}



.spine-group.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
}

button{
  background-color: #ffcc00;
}

.form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-field {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;

}

.form-field.full-width {
  grid-column: 1 / -1;
} * */ 


.intake-crescent {
  width: 30%;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  fill: #ffcc00;
}

.intake-crescent > svg {
  width: 100%;
  height: 100%;
}


/* 
.submission-status-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.popup-content {
  text-align: center;
} */




.backgroundSecBg {
  margin: 0;
  padding: 0;
  margin-left: 200px;
}

.intake-form-container{
  margin-left: 200px;
  width: 1000px;
  
}

.backgroundSecBg{
  background-color: #ffcc00;
  border-bottom: 20px;
}